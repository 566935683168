.category-list {
	-moz-column-count: 3;
	-moz-column-gap: 15px;
	-webkit-column-count: 3;
	-webkit-column-gap: 15px;
	column-count: 3;
	column-gap: 15px;
	list-style: none;
	padding:0 0 0 0;
}

@media (max-width: 767px) { 
	.category-list {
		-moz-column-count: 2;
		-moz-column-gap: 15px;
		-webkit-column-count: 2;
		-webkit-column-gap: 15px;
		column-count: 2;
		column-gap: 15px;
	}
}