.collection-item {
	h5 {
		font-size: 1.2rem;
		margin:-5px 0 0 0;
	}
	.author {
		margin-top:5px;
		margin-bottom:5px;
	}
	.description {
		margin: 0 0 3px 0;
	}
	.meta {
		margin:0 0 0 0
	}
}