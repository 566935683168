.sidebar-wrapper {
	height:100%;
	max-width:300px;
	background:#f9f9f9;
	border-right:1px solid #eee; 
}
.sidebar-footer {
	span {
		font-size: 9px;
		line-height: 1.5;
	}
	background:#f2f2f2;
}
.sidebar {
	height: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	max-width:300px;
	background:#f9f9f9;
}
.drawer {
	min-width: 300px;
	background:#f9f9f9;
}