.post {
	.header {
		.chip {
			background-color: #de9002;
			font-size: '12px';
			letter-spacing: 0;
		}
	}
	.content {
		white-space: pre-line;
		overflow-wrap: break-word;
		word-wrap: break-word;

		.shared-content {
			margin-top:15px;
			.video-wrapper {
				overflow: hidden;
				border-radius: 8px;
				margin-bottom: 10px;
			}
			.rich-wrapper {
				.icon-external-link {
					opacity: 0.7;
					margin-right:10px;
					width:18px;
					position: relative;
					top:-1px;
				}
				img {
					border-radius: 8px;
					margin-bottom: 10px;
				}
				a {
					color:inherit;
				}
				h6 {
					font-weight: 600;
					font-size:18px;
				}
			}
		}
		.article {
			
			ul, ol {
				padding: 0 0 0 20px;
				li {
					margin:0 0 5px 0;
				}
			}

			.image-holder {
				padding:0;
				margin:0 0 15px 0;
				img {
					border-radius: 6px;
					max-width: 100%;
				}
			}
		
			.embed-holder {
				margin: 0 0 15px 0;
		
				&.iframely {
					border-radius: 6px;
					overflow:hidden;
				}
			}
		}	
	}
	.footer {
		.vote-icon {
			width:20px;
			height:20px;
		}
	}
	.reply {
		border-left:1px solid rgba(0, 0, 0, 0.08);
		padding-left:15px;
	}

}