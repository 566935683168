.filter-list {
	-moz-column-count: 2;
	-moz-column-gap: 0px;
	-webkit-column-count: 2;
	-webkit-column-gap: 0px;
	column-count: 2;
	column-gap: 0px;
	color:#333;
}
.filter-list-toggle-button {
	min-width:140px;
}